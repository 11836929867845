import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { MenuPageContentModel } from '../model/pagecontent';


@Injectable({
    providedIn: 'root',
})
export class PageContentService {
    apiUrl: any;
    BearerToken: any;
    Typelist: MenuPageContentModel[] = [];
    httpOptions: { headers: any; };
    private AllTypelists: any[] = [];
    private AllTypelistsHome: any[] = [];

    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }

    setAllTypeCitieslistCall(data: any[]): void {
        this.AllTypelists = data;
    }

    async geAllTypeCitieslistCall(): Promise<any[]> {
        if (this.AllTypelists.length > 0) {
            // If the productList is already populated, return it without making an API call
            return this.AllTypelists;
        } else {
            try {
                // If AllTypelists is empty, make an API call here to fetch the data
                const apiData = await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/typecardlist");
                // Update AllTypelists with the fetched data
                this.AllTypelists = apiData;
                // Return the fetched data
                return apiData;
            } catch (error) {
                // Handle errors here
                console.error('Error fetching typecardlist:', error);
                return []; // or throw the error, depending on your error-handling strategy
            }
        }
    }


    setAllTypeCitieslistHomeCall(data: any[]): void {
        this.AllTypelistsHome = data;
    }

    async geAllTypeCitieslistHomeCall(): Promise<any[]> {
        if (this.AllTypelistsHome.length > 0) {
            // If the productList is already populated, return it without making an API call
            return this.AllTypelistsHome;
        } else {
            try {
                // If AllTypelists is empty, make an API call here to fetch the data
                const apiData = await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/typecardlisthome");
                // Update AllTypelists with the fetched data
                this.AllTypelistsHome = apiData;
                // Return the fetched data
                return apiData;
            } catch (error) {
                // Handle errors here
                console.error('Error fetching AllTypelistsHome:', error);
                return []; // or throw the error, depending on your error-handling strategy
            }
        }
    }

    async getAllTypeCitieslist(): Promise<any[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/getCitylist");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching getCitylist:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }


    async getAllTypelocationlist(): Promise<any[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/getlocationlist");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching location:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }


    async getAllGetSearchconfiglist(): Promise<any[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/getSearchconfig");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching location:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    async getAllTypecardlist(): Promise<any[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/typecardlist");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching Typecardlist:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    async getAllTypecardlistHome(): Promise<any[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/typecardlisthome");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching Typecardlist:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

  
    // async getAllMenucardlistId(id: number): Promise<MenuPageContentModel[]> {
    //     try {
    //         return await this.service.Data.ExecuteAPI_Get<any[]>("MenuPageContent/GetAllMenuMastersmenuId/{" + id + "}?menuid=" + id);
    //     } catch (error) {
    //         // Handle errors here
    //         console.error('Error fetching Typecardlist:', error);
    //         return []; // or throw the error, depending on your error-handling strategy
    //     }
    // }
    // async getAllMenucardlistId(id: number): Promise<any[]> {
    //     try {
    //         return await this.service.Data.ExecuteAPI_Get<any[]>("TypeDetail/typecardlist/{" + id + "}?typeid=" + id);
    //     } catch (error) {
    //         // Handle errors here
    //         console.error('Error fetching Typecardlist:', error);
    //         return []; // or throw the error, depending on your error-handling strategy
    //     }
    // }
    async getAllMenucardlistId(id: number): Promise<any[]> {
        try {
            return  await this.service.Data.ExecuteAPI_Get<any[]>("MenuPageContent/GetAllMenuMastersmenuId/" + id + "?menuid=" + id);
       
        } catch (error) {
           
        
            return []; 
        }
    }

    async getAllTypelist(): Promise<MenuPageContentModel[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<MenuPageContentModel[]>("TypeDetail");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching Typelist:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    async getTypeById(id: number): Promise<MenuPageContentModel> {
        try {
            return await this.service.Data.ExecuteAPI_Get<MenuPageContentModel>("TypeDetail/typeid/{id}?typeid=" + id);
        } catch (error) {
            console.error('Error fetching Typelist:', error);
            return null;
        }
    }

    async addType(Type: MenuPageContentModel) {
        Type.menuId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("TypeDetail", Type);
        return res;
    }

    async updateType(Type: MenuPageContentModel): Promise<Observable<MenuPageContentModel>> {
        let res = await this.service.Data.ExecuteAPI_Put("TypeDetail", `${Type.menuId}?Typeid=${Type.menuId}`, Type);
        return res;
    }

    async deleteType(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("TypeDetail/delete/", `${id}?typeid=${id}`);
        return res;
    }
}
