import { Component, OnInit } from '@angular/core';
import { FAQService } from '../../services/faq.service';
import { FAQ } from '../../model/faq';
import { SystemService } from '../../services/system.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  rowData: any;
  constructor(
    private fAQService: FAQService,
    public service: SystemService
  ) { 
    
  }

  ngOnInit(): void {
    this.loadGallery();
  }

  loadGallery() {
    this.service.App.ShowLoader = true;
    this.fAQService.getAllfaq().then(gallerylist => {
        console.log(gallerylist, "gallerylist")
        this.rowData = gallerylist;
    });
    this.service.App.ShowLoader = false;

}

}
