import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any[] {
    if (!items || !filter) {
      return items;
    }
    // Filter items array based on filter object
    return items.filter(item => this.applyFilter(item, filter));
  }

  applyFilter(item: any, filter: any): boolean {
    // Implement your filter logic here
    // For example, if filter is an object { Menu_RootID: 'Root' },
    // Check if the item's Menu_RootID property matches the filter value
    for (const field in filter) {
      if (filter.hasOwnProperty(field)) {
        if (item[field] !== filter[field]) {
          return false;
        }
      }
    }
    return true;
  }
}
