import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeGuestComponent } from './components/pages/home-guest/home-guest.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-guest/navbar-style-guest.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { PageContentComponent } from './components/pages/pagecontent/pagecontent';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertifyService } from './components/services/alertify.service';
import { AuthService } from './components/services/auth.service';
import { NewBlogService } from './components/services/newBlog.service';
import { PostsService } from './components/services/posts.service';
import { UserServiceService } from './components/services/user-service.service';
import { NavbarStyleAdminComponent } from './components/common/navbar-style-admin/navbar-style-admin.component';
import { NavbarStyleUserComponent } from './components/common/navbar-style-user/navbar-style-user.component';
import { HomeUserComponent } from './components/pages/home-user/home-user.component';
import { HomeComponent } from './components/pages/home/home.component';
import { CookiepolicyComponent } from './components/pages/cookie-policy/cookie-policy.component';
import { SystemService } from './components/services/system.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill'
import { ProfileFormComponent } from './components/pages/profile/profile';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { AuthGuard } from './auth.guard';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { GalleryViewComponent } from './components/pages/listing/gallery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContinueButtonComponent } from './components/questionnaire/continue-button/continue-button.component';
import { MultiOptionComponent } from './components/questionnaire/multi-option/multi-option.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { SingleOptionComponent } from './components/questionnaire/single-option/single-option.component';
import { TextAnswerComponent } from './components/questionnaire/text-answer/text-answer.component';
import { ApplynowComponent } from './components/questionnaire/applynow.component';
import { DashboardUserComponent } from './components/pages/home-user/dashboard/dashboardUser.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FinalpopupComponent } from './components/questionnaire/finalpopup/finalpopup.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BlogDetailsUserViewComponent } from './components/pages/blog-details/blog-details-user-view.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { NgxYoutubePlayerModule } from '@ngx-yanis/ngx-youtube';
import { CommonModule } from '@angular/common';
import { Reset_PasswordComponent } from './components/pages/login-register/reset_password';
import { FilterPipe } from './components/pipes/filterpipe.pipe';
import { VideogalleryComponent } from './components/pages/videogallery/videogallery.component';
import { ImagegalleryComponent } from './components/pages/imagegallery/imagegallery.component';
import { ImagegalleryPictureComponent } from './components/pages/imagegallerypicture/imagegallerypicturecomponent';
const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain: 'localhost'// it is recommended to set your domain, for cookies to work properly
    },
    palette: {
      popup: {
        background: '#000'
      },
      button: {
        background: '#f1d600'
      }
    },
    theme: 'edgeless',
    type: 'opt-out',
    layout: 'my-custom-layout',
    layouts: {
      "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements:{
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}}
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>,
        <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our
        <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
      </span>
      `,
    },
    content:{
      message: 'By using our site, you acknowledge that you have read and understand our ',

      cookiePolicyLink: 'Cookie Policy',
      cookiePolicyHref: 'https://cookie.com',

      privacyPolicyLink: 'Privacy Policy',
      privacyPolicyHref: 'https://privacy.com',

      tosLink: 'Terms of Service',
      tosHref: 'https://tos.com',
    }
  };
@NgModule({
    declarations: [

        AppComponent,
        PageContentComponent,
        PreloaderComponent,
        HomeGuestComponent,
        NavbarStyleTwoComponent,
        FooterStyleTwoComponent,
        AboutComponent,
        TeamComponent,
        PricingComponent,
        CategoryComponent,
        FaqComponent,
        TestimonialsComponent,
        ErrorComponent,
        LoginRegisterComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        ComingSoonComponent,
        ShopComponent,
        CartComponent,
        CheckoutComponent,
        ProductsDetailsComponent,
        ListingComponent,
        ListingDetailsComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactComponent,
        NavbarStyleUserComponent,
        NavbarStyleAdminComponent,
        HomeUserComponent,
        HomeComponent,
        CookiepolicyComponent,
        ProfileFormComponent,
        GalleryViewComponent,
        BlogDetailsUserViewComponent,
        DashboardUserComponent,
        FinalpopupComponent,
        QuestionnaireComponent,
        TextAnswerComponent,
        ContinueButtonComponent,
        SingleOptionComponent,
        MultiOptionComponent,
        ApplynowComponent,
        Reset_PasswordComponent,
        FilterPipe,
        VideogalleryComponent,
        ImagegalleryComponent,
        ImagegalleryPictureComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        RouterModule.forRoot(routes, { useHash: false, enableTracing: false }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        QuillModule.forRoot(),
        AgGridModule,
        NgbModule,
        ModalModule.forRoot(),
        FullCalendarModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgxYoutubePlayerModule,

    ],
    providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }, AuthGuard, PostsService,
        UserServiceService,
        AlertifyService,
        AuthService,
        SystemService,
        NewBlogService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
