
<style>
    .gallery-image {
    width: 600px; /* Specify the desired width */
    height: 300px; /* Specify the desired height */
    object-fit: cover; /* Ensure the image covers the specified dimensions */
}

</style>
<app-navbar-style-guest></app-navbar-style-guest>

<section class="category-area pt-80">

</section>
<section class="city-area-two pb-80">
    <div class="container">
        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/imagegallery">Back</a></li>
                        <!-- <li><i class='bx bx-chevron-right'></i></li> -->
                        <h1>Photos of {{obj?.galleryBuget}}</h1>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                   

                </div>
            </div>
        </div> -->
        <div class="row pt-45">

            <div class="col-lg-4 col-md-6" *ngFor="let obj of imagelist">

                <div class="city-card">
                    <img [src]="apiPath + '/Documents/appImages/' + obj.galleryPhoto" alt="Images"
                        class="gallery-image">
                    <div class="content">

                        <h3>{{ obj.description }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>