import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { ImageGallery } from '../model/imagegallery';


@Injectable({
  providedIn: 'root'
})
export class ImageGalleryService {
  getCategoryNameById(id: number) {
    throw new Error('Method not implemented.');
  }
  private apiPath: string;

  private ImageGallery: any[] = [];
  constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


  async addGallery(ImageGallery: ImageGallery) {
    ImageGallery.imageGalleryId = 0;
    let res = await this.service.Data.ExecuteAPI_Post("ImageGallery", ImageGallery);
    console.log(res);
    return res;
  }


  async getGallery(id: number): Promise<any> {
    console.log(id, "ideditapinumber")
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("ImageGallery/{id}?imageGalleryId=" + id);
    } catch (error) {
      console.error('Error fetching categories:', error);
      return null;
    }
  }

  async getGalleryTypeDetails(id: number): Promise<any> {
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("Gallery/typeDetails/{id}?typeId=" + id);
    } catch (error) {
      console.error('Error fetching categories:', error);
      return null;
    }
  }

  async getAllGalleryshomebanner() {
    //return this.http.get<any>(this.apiPath + '/api/Gallery');
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("ImageGallery/homeBanner");
    } catch (error) {
      console.error('Error fetching ImageGallerylist:', error);
      return [];
    }
  }

  async getAllGallerys() {
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("ImageGallery");

    } catch (error) {
      console.error('Error fetching ImageGallerylist:', error);
      return [];
    }
  }
  async getAllImages() {
    if (this.ImageGallery.length > 0) {
      return this.ImageGallery;
    } else {
      try {
        const apiData = await this.service.Data.ExecuteAPI_Get<any>("ImageGallery");
        this.ImageGallery = apiData;
        return apiData;
      } catch (error) {
        console.error('Error fetching ImageGallerylist:', error);
        return [];
      }
    }
  }

  getUserGallerys(userName: string) {
    return this.http.get<ImageGallery[]>(this.apiPath + '/api/Gallery/userGallery/' + userName);
  }

  async updateGallery(ImageGallery: any) {
    console.log(ImageGallery, "imageGalleryinapicall");
    let res = await this.service.Data.ExecuteAPI_Put("ImageGallery", `${ImageGallery.ImageGalleryId}?imagegalleryid=${ImageGallery.ImageGalleryId}`, ImageGallery);
    console.log(res, "resafterimageupdate");
    return res;
  }

  async deleteGallery(id: number) {
    console.log(id, "iddeletebefore")
    let res = await this.service.Data.ExecuteAPI_Delete("ImageGallery", `{id}?imagegalleryid=${id}`);
    console.log(res, "resafetrdelete")
    return res;
  }

  async getAllImagess(galleryBuget?: string) {
    try {
      let apiUrl = "ImageGallery";
      if (galleryBuget) {
        apiUrl += `?galleryBuget=${galleryBuget}`;
      }
      return await this.service.Data.ExecuteAPI_Get<any>(apiUrl);
    } catch (error) {
      console.error('Error fetching ImageGallerylist:', error);
      return [];
    }
  }


}
