import { Component, OnInit } from '@angular/core';
import { VideoGallery } from '../../model/videogallery';
import { FormBuilder } from '@angular/forms';
import { VideoGalleryService } from '../../services/videoGallery.service';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'app-video',
  templateUrl: './videogallery.component.html',
  styleUrls: ['./videogallery.component.scss']
})
export class VideogalleryComponent implements OnInit {


    gallery: VideoGallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    fileName: any;
    documentTypelist: any;
    typelist: any;
    galleryItems: any;
    isLoading = false;
    apiPath: any;
    constructor(private fb: FormBuilder,
        private videogalleryService: VideoGalleryService,
        public service: SystemService) { }

    ngOnInit() {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadVideoGallery();
    }
    isRead = false;
    isReady= false;
    onReadyChange($event: boolean) {
      this.isRead = $event;
    }
    loadVideoGallery() {
        this.service.App.ShowLoader = true;
        this.videogalleryService.getAllGallerys().then(gallerylist => {
            this.galleryItems = [];
            for (const galleryItem of gallerylist) {
                const newItem = { ...galleryItem };
                newItem.videoGalleryId = galleryItem.videoGalleryId;
                newItem.videoCategory = galleryItem.videoCategory;
                newItem.videoLink = galleryItem.videoLink;
                newItem.videoDescp = galleryItem.videoDescp;
                newItem.VideoKey = this.extractValueAfterEqualSign(galleryItem.videoLink);
                this.galleryItems.push(newItem);
            }
            //this.galleryItems = gallerylist;
            console.log(JSON.stringify(this.galleryItems));
        });
        this.service.App.ShowLoader = false;
    }


     extractValueAfterEqualSign(url: string): string | null
     {
        const queryString = url.split('?')[1]; // Get the query string part of the URL
        if (!queryString) return null; // If there's no query string, return null

        const keyValuePairs = queryString.split('&'); // Split the query string into key-value pairs
        for (const pair of keyValuePairs) {
            const [key, value] = pair.split('='); // Split each key-value pair into key and value
            if (key && key.trim() === 'v') { // If the key is 'v' (for video ID in YouTube URLs)
                return value; // Return the value associated with 'v'
            }
        }
        return null; // If 'v' parameter is not found, return null
    }
}
