import { Component, OnInit } from '@angular/core';

import { ImageGallery } from '../../model/imagegallery';

import { FormBuilder } from '@angular/forms';
// import { VideoGalleryService } from '../../services/videoGallery.service';
import { ImageGalleryService } from '../../services/imagegallery.service';
import { SystemService } from '../../services/system.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-imagegallery',
    templateUrl: './imagegallery.component.html',
    styleUrls: ['./imagegallery.component.scss']
})
export class ImagegalleryComponent implements OnInit {


    gallery: ImageGallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    imageUrl: string;
    typelist: any;
    isLoading = false;
    apiPath: any;
    imagelist: ImageGallery[];
    filteredImages: ImageGallery[];

    constructor(private fb: FormBuilder,
        private imagegalleryservice: ImageGalleryService,
        private router: Router,
        public service: SystemService) { }

    ngOnInit() {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadimageGallery();
    }
    isRead = false;
    isReady = false;
    onReadyChange($event: boolean) {
        this.isRead = $event;
    }

    showImagesByCategory(category: string) {
        this.filteredImages = this.imagelist.filter(obj => obj.galleryBuget === category);
    }
 
    loadimageGallery() {
        this.service.App.ShowLoader = true;
        this.imagegalleryservice.getAllImages().then(imagelist => {
            const filteredList = [];
            const uniqueBugets = [];
            for (const imageItem of imagelist) {
                if (!uniqueBugets.includes(imageItem.galleryBuget)) {
                    uniqueBugets.push(imageItem.galleryBuget);
                    filteredList.push(imageItem);
                }
            }
            this.imagelist = filteredList;
        }).finally(() => {
            this.service.App.ShowLoader = false;
        });
    }
    
    navigateToImageGalleryPicture(id: number) {
        this.router.navigate(['/imagegallerypicture', id]);
    }
    extractValueAfterEqualSign(url: string): string | null {
        const queryString = url.split('?')[1]; // Get the query string part of the URL
        if (!queryString) return null; // If there's no query string, return null

        const keyValuePairs = queryString.split('&'); // Split the query string into key-value pairs
        for (const pair of keyValuePairs) {
            const [key, value] = pair.split('='); // Split each key-value pair into key and value
            if (key && key.trim() === 'v') { // If the key is 'v' (for video ID in YouTube URLs)
                return value; // Return the value associated with 'v'
            }
        }
        return null; // If 'v' parameter is not found, return null
    }
}
