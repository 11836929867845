<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <!-- <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li> -->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>HRM VISAS | India | Dubai | Europe.</h3>
                        <!-- <h3></h3> -->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3><a href="mailto:admin&#64;hrmvisas.com">Email:admin&#64;hrmvisas.com</a></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-to-do-list"></i>
                        <h3><a href="tel:+(91)9743004400">+(91) – 97430-04400</a></h3>
                        <h3><a href="tel:+(971)585669400">+(971) – 585669400</a></h3>
                        <h3><a href="tel:+(421)950202052">+(421) – 950202052</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.829631009366!2d55.26992347593007!3d25.208967331222876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4387bf533439%3A0x880b47ede0507475!2sHRM%20Visas!5e0!3m2!1sen!2ssk!4v1705545200320!5m2!1sen!2ssk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="contact-wrap">
            <div class="contact-form">
                <span>SEND MESSAGE</span>
                <h2>Contact With Us</h2>

                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-phone'></i>
                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-file'></i>
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <i class='bx bx-envelope'></i>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

 <app-footer-style-two></app-footer-style-two>
