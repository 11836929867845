
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Welcome</span>
            <h2>HRM <b> VISAS </b></h2>
        </div>

        <div class="card-body">
            <div class="col-12">
                <div class="row" style="display: flex;justify-content: center;">
                    <div
                        class="col-12 col-sm-4 col-md-3 mb-3 text-center border pt-2 pb-2 dv-profile-left">
                        <div class="m-card-profile__pic-wrapper">
                            <img *ngIf="imageUrl" class="img-fluid" [src]="imageUrl"
                                style="width:160px;" />
                        </div>
                      <h4>{{displayName}}</h4>
                      <a href="/profiles" *ngIf="!imageUrl">Please complete your profile.</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pt-45">
          <p> We have been in this specific field of Visa Services since 2008. We have gained experience by processing Thousands of Visa applications. We have been in this specific field of Visa Services since 2008. We have gained experience by processing Thousands of Visa applications. We do Visas, Travel, Flight, Hotel, Insurance, Documentation make easy!</p>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                  <div class="content">
                        <i class="flaticon-deal"></i>
                         <h3>Blogs</h3>
                         <!-- <h2><sup>$</sup>60</h2>
                        <span>Only this week</span>-->
                    </div>
                   <!--  <ul>
                        <li>One Listing</li>
                        <li>90 Days Availability</li>
                        <li>Non-Featured</li>
                        <li>Limited Support</li>
                    </ul> -->
                    <a routerLink="/blog" class=" default-btn border-radius"> View Blogs <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                     <div class="content">
                        <i class="flaticon-megaphone"></i>
                       <h3>Listings</h3>
                        <!-- <h2><sup>$</sup>105</h2>
                        <span>Only this month</span>-->
                    </div>
                    <!-- <ul>
                        <li>Ten Listings</li>
                        <li>Lifetime Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                    </ul> -->
                    <a routerLink="/listing" class=" default-btn border-radius"> View Listings <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-diamond"></i>
                        <h3>Future</h3>
                        <!-- <h2><sup>$</sup>134</h2>
                        <span>Only this year</span> -->
                    </div>
                    <!-- <ul>
                        <li>Unlimited listing</li>
                        <li>Lifetime Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                    </ul> -->
                    <a routerLink="/about" class=" default-btn border-radius"> About us <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


