import { Component, OnInit } from '@angular/core';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { AlertifyService } from '../../services/alertify.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BannerService } from '../../services/banner.service';
import { SystemService } from '../../services/system.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RequestCallBackService } from '../../services/requestcallback.service';
import { CategoryService } from '../../services/category.service';
import { ProfileService } from '../../services/profileService';

@Component({
    selector: 'app-listing-details',
    templateUrl: './listing-details.component.html',
    styleUrls: ['./listing-details.component.scss']
})
export class ListingDetailsComponent implements OnInit {
    id: any; requestForm: FormGroup;
    isLoading: boolean = true;
    typeCardlist: any;
    bannerPhoto: any;
    listingFeaturesArray: string[];
    headFeaturesArray: string[];
    apiPath: any;
    urlSafe: any;
    cards: string[]; categotyList: any;
    typeCardlistValues: any;
    hasJob: boolean;

    constructor(private fb: FormBuilder, private categoryService: CategoryService,
        private alertify: AlertifyService, private ProfileService: ProfileService,
        private router: Router, public sanitizer: DomSanitizer,
        private typeService: TypeService,
        private requestCallBackService: RequestCallBackService,
        public service: SystemService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            localStorage.setItem('typeId', this.id); //!IMP
            // Now you can use the 'id' in your component logic
        });
        // this.id = this.route.snapshot.paramMap.get('id');

    }

    ngOnInit(): void {
        this.CreateRequestForm();
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadCategories();
        this.loadtypecardlist();
        this.loadtypecardAlllist();
    }

    
    async loadtypecardlist() {
        this.isLoading = true;
        console.log(this.isLoading);
        await this.typeService.getAllTypecardlistId(this.id).then(typeCardlist => {
            //this.typeCardlist = typeCardlist;
            typeCardlist.forEach(card => {
                card.categoryNames = [];
                // Split the comma-separated string into an array of category IDs
                const cardCategoryIds = card.categoryId.split(',');
                console.log(cardCategoryIds);
                // Find all matching categories for each category ID in the array
                cardCategoryIds.forEach(cardCategoryId => {
                    console.log(cardCategoryId);
                    console.log(JSON.stringify(this.categotyList));
                    const matchedCategory = this.categotyList.find(category => category.categoryId === Number(cardCategoryId));
                    console.log(matchedCategory);
                    if (matchedCategory) {
                        // Add the matching category name to the array
                        //card.categoryNames.push(matchedCategory.categoryName);
                        card.categoryNames += ` ${matchedCategory.categoryName}`;
                    } else {
                        console.warn(`No matching category found for categoryId: ${cardCategoryId}`);
                        // You can choose to set a default category name or handle this case differently
                    }
                });
            });
            console.log(typeCardlist[0].categoryNames);
            if (typeCardlist[0].categoryNames.includes('job')) {
                this.hasJob = true;
            }
            else {
                this.hasJob = false;
            }
            this.typeCardlist = typeCardlist;
            console.log(this.typeCardlist);
            // this.typeCardlist = typeCardlist;
            try {
                this.bannerPhoto = typeCardlist[0].bannerPhoto;
                this.urlSafe = this.sanitizer.bypassSecurityTrustHtml(typeCardlist[0].mapLink);
                console.log(this.urlSafe);
                console.log(JSON.stringify(this.typeCardlist));
                console.log(this.typeCardlist[0].extra5);
            } catch { }
            try {
                this.listingFeaturesArray = this.typeCardlist[0].listingFeatures.split(',').map(value => value.trim());
                console.log(this.listingFeaturesArray);
            } catch { }
            try {
                this.headFeaturesArray = this.typeCardlist[0].headFeatures.split(',').map(value => value.trim());
                console.log(this.headFeaturesArray);
            } catch { }
        });
        this.isLoading = false;
    }

    async loadtypecardAlllist() {
        this.isLoading = true;
        const allTypeCitieslist = await this.typeService.geAllTypeCitieslistHomeCall();
        this.typeCardlistValues = allTypeCitieslist;
        console.log(this.typeCardlistValues);
        const colorCodes = this.typeCardlistValues.map(obj => '#' + obj.typeId.toString(16).padStart(6, '0'));
        this.cards = colorCodes;
        console.log(this.cards);
        this.isLoading = false;
    }
    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    loadCategories() {
        this.isLoading = true;
        console.log(this.isLoading);
        this.categoryService.getAllCategories().then(categories => {
            console.log(JSON.stringify(categories));
            this.categotyList = categories;
        });
        this.isLoading = false;
    }
    CreateRequestForm() {
        // Initialize the form group with validators
        this.requestForm = this.fb.group({
            yourName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phoneNo: [''],
            dateTime: [''],
            yourMessage: ['', Validators.required],
        });
    }

    async onSubmit() {
        if (this.requestForm.valid) {
            let obj = this.requestForm.getRawValue();
            console.log(JSON.stringify(obj));
            let res = this.requestCallBackService.addRequestCallBack(obj);
            if (res) {
                this.alertify.success("Requested Sent Successfully");
            }
        }
        this.requestForm.reset();
    }

}
