
<style>
    .gallery-image {
    width: 600px; /* Specify the desired width */
    height: 300px; /* Specify the desired height */
    object-fit: cover; /* Ensure the image covers the specified dimensions */
}

</style>

<app-navbar-style-guest></app-navbar-style-guest>


<section class="category-area pt-100">

</section>

<section class="city-area-two pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span>Photos</span>
                    <h2>Photos Gallery</h2>
                    <p>List of Photos</p>
                </div>
            </div>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let obj of imagelist">
                <div class="city-card">
                    <img [src]="apiPath + '/Documents/appImages/' + obj.galleryPhoto" alt="Images" class="gallery-image">
                    <div class="content">
                        <h3>{{ obj.galleryBuget }}</h3>
                        <a [routerLink]="['/imagegallerypicture', obj.galleryBuget]"><h4>View</h4></a>
                    </div>
                </div>
            </div>
        </div>
        
        
        



    </div>
</section>

<app-footer-style-two></app-footer-style-two>