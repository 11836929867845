<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img  [src]="imageUrl" alt="Logo" width="100px"></a>
    </div>



    <!-- <div style="overflow: visible; height: 100px;">
        <ul class="menu">
          <li *ngFor="let menu of MenuDetailsDisp | filterPipe: { menu_RootID: 'Root' }">
            <a [href]="menu.menuFileName">{{ menu.menuDisplayTxt }}</a>
            <ul class="sub-menu">
              <li *ngFor="let submenu of MenuDetailsDisp | filterPipe: { Menu_RootID: menu.Menu_ChildID }"
                  (mouseover)="showsubMenu(1, submenu.Menu_ChildID)"
                  (mouseout)="showsubMenu(0, submenu.Menu_ChildID)">
                <a [href]="submenu.MenuFileName">{{ submenu.menuDisplayTxt }}</a>
                <ul *ngIf="showSubDetails" class="sub-menu2">
                  <li *ngFor="let sub1menu of MenuDetailsDisp | filterPipe: { Menu_RootID: submenu.subChildIDS }"
                      (mouseover)="showsubMenu(3, '9')">
                    <a [href]="sub1menu.MenuFileName">{{ sub1menu.menuDisplayTxt }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div> -->


    <!-- <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo2.png" alt="Logo"
                        width="200px"></a>


                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                        <li *ngFor="let menu of MenuDetailsDisp | filterPipe: { menu_RootID: 'Root' }">
                            <a [href]="menu.menuURL">{{ menu.menuDisplayTxt }}</a>
                            <ul class="sub-menu">
                                <ng-container *ngFor="let submenu of MenuDetailsDisp">
                                    <ul class="dropdown-menu">
                                        <li *ngIf="submenu.menu_RootID === submenu.menu_RootID">
                                            <a [href]="submenu.menuURL">{{ submenu.menuDisplayTxt }}</a>
                                            <ul class="sub-menu2">
                                                <li *ngFor="let subsubmenu of MenuDetailsDisp">
                                                    <a [href]="subsubmenu.menuURL">{{
                                                        subsubmenu.menuDisplayTxt}}</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </ng-container>
                            </ul>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    </div> -->


    <!-- <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo2.png" alt="Logo"
                        width="200px"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home <i class='bx bx-plus'></i></a>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li>

                       <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/category" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Category</a>
                                </li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-plus'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Shop</a></li>

                                        <li class="nav-item"><a routerLink="/cart" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                        <li class="nav-item"><a routerLink="/checkout" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                        <li class="nav-item"><a routerLink="/products-details" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/login-register" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Login/Register</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms &
                                        Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                        Policy</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coming
                                        Soon</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Visas <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Student Visa</a></li>
                                <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Work Visa</a></li>
                                <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Investor Visa</a></li>
                                <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Short term Visa</a></li>
                                <li class="nav-item"><a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">e-visas</a></li>

                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Flight & Hotel <i class='bx bx-plus'></i></a>
                        </li>
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Insurance<i class='bx bx-plus'></i></a>

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Tour Packages<i class='bx bx-plus'></i></a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                               <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact" *ngIf="userName"></i>

                            </div>
                        </div>
                        <div class="side-item">
                            <div class="search-box">
                                <span *ngIf="userName">Welcome, {{userName}}</span>
                            </div>
                        </div>

                        <div class="side-item">
                            <div class="nav-add-btn">

                                <a routerLink="/login-register" *ngIf="!userName" class="default-btn">Log
                                    in<i class='bx bx-plus'></i></a>
                                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent"
                                    *ngIf="userName">
                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <button class="default-btn" *ngIf="userName" (click)="logout()">Log Out<i
                                                    class='bx bx-plus'></i></button>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a routerLink="/profiles" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Profile</a></li>
                                                <li class="nav-item"><a routerLink="/pricing" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div> -->


    <!--
    <ul>
        <ng-container *ngFor="let menuItem of menuItems">
          <li>{{ menuItem.menuName }}</li>
          <ul *ngIf="menuItem.subMenus && menuItem.subMenus.length > 0">
            <ng-container *ngFor="let submenu of menuItem.subMenus">
              <li>
                <a *ngIf="submenu.subMenus && submenu.subMenus.length > 0" href="{{ submenu.menuUrl }}">{{ submenu.menuName }}</a>
                <span *ngIf="!submenu.subMenus">{{ submenu.menuName }}</span>
                <ul *ngIf="submenu.subMenus && submenu.subMenus.length > 0">
                  <ng-container *ngFor="let subSubmenu of submenu.subMenus">
                    <li><a href="{{ subSubmenu.menuUrl }}">{{ subSubmenu.menuName }}</a></li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </ul> -->


    <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img [src]="imageUrl" alt="Logo"
                        width="200px"></a>


                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                        <li class="nav-item" *ngFor="let menuItem of MenuDetailsDisp">
                            <a routerLink="/pagecontent/{{ menuItem.menuId }}" class="nav-link">{{ menuItem.menuName}} <i
                                    class='bx bx-plus'></i></a>
                            <ul class="dropdown-menu">
                                <ng-container *ngFor="let submenuItem of menuItem.subMenus">
                                    <li class="nav-item" *ngIf="menuItem.subMenus && menuItem.subMenus.length > 0">
                                        <a routerLink="/pagecontent/{{ submenuItem.menuId }}" class="nav-link">{{submenuItem.menuName }} </a>
                                        <ul class="dropdown-menu" *ngIf="submenuItem.subMenus && submenuItem.subMenus.length > 0">
                                            <li class="nav-item">
                                                <ng-container *ngFor="let subSubmenu of submenuItem.subMenus">
                                                    <li><a routerLink="/pagecontent/{{ subSubmenu.menuId }}" class="nav-link">{{ subSubmenu.menuName }}</a></li>
                                                    <ul class="dropdown-menu" *ngIf="subSubmenu.subMenus && subSubmenu.subMenus.length > 0">
                                                        <ng-container *ngFor="let subSubSUbmenuItem of subSubmenu.subMenus">
                                                            <li><a routerLink="/pagecontent/{{ subSubSUbmenuItem.menuId }}" class="nav-link">{{subSubSUbmenuItem.menuName }}</a> </li>
                                                        </ng-container>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>




                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact" *ngIf="userName"></i>

                            </div>
                        </div>
                        <div class="side-item">
                            <div class="search-box">
                                <span *ngIf="userName">Welcome, {{userName}}</span>
                            </div>
                        </div>

                        <div class="side-item">
                            <div class="nav-add-btn">

                                <a routerLink="/login-register" *ngIf="!userName" class="default-btn">Log
                                    in<i class='bx bx-plus'></i></a>
                                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent"
                                    *ngIf="userName">
                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <button class="default-btn" *ngIf="userName" (click)="logout()">Log Out<i
                                                    class='bx bx-plus'></i></button>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a routerLink="/profiles" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Profile</a></li>
                                                <!-- <li class="nav-item"><a routerLink="/pricing" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li> -->
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>











    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact"></i> <span>{{userName}}</span>
                            </div>
                        </div>


                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/login-register" *ngIf="!userID" class="default-btn border-radius">Log
                                    in<i class='bx bx-plus'></i></a>
                                <a routerLink="/" class="default-btn" *ngIf="userID">Log Out<i
                                        class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>
            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
