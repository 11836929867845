import { Component, OnInit } from '@angular/core';
import { PageContentService } from '../../services/pagecontent.service';
import { AlertifyService } from '../../services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestCallBackService } from '../../services/requestcallback.service';
import { MenuPageContentModel } from '../../model/pagecontent';

@Component({
    selector: 'app-pagecontent',
    templateUrl: './pagecontent.html',
    styleUrls: ['./pagecontent.scss']
})
export class PageContentComponent implements OnInit {
    apiPath: string;
    //pagecontent: Array<MenuPageContentModel>;
    pagecontent: any;
    imageUrl: string;
    isLoading: boolean = true;
    requestForm: FormGroup;
    menuId: number;
    extra1: any;
    dataAvailable: boolean = true; // Flag for data availability

    constructor(private alertify: AlertifyService,
        private router: Router,
        public service: SystemService,
        private route: ActivatedRoute,
        private requestCallBackService: RequestCallBackService,
        private pagecontentservice: PageContentService,
        private fb: FormBuilder) {
    }

    ngOnInit(): void {
        // this.pagecontent = [];
        this.apiPath = this.service.Settings.Base_API_URL;
        this.route.params.subscribe(params => {
            this.menuId = params['id'];
            localStorage.setItem('menuId', this.menuId.toString());
            this.loadPageContent(this.menuId);
        });
        this.createRequestForm();
    }

    loadPageContent(id: number): void {
        console.log(this.pagecontent, '.this.pagecontent');
        this.service.App.ShowLoader = true;
        this.pagecontentservice.getAllMenucardlistId(id).then(menuItems => {
            if (menuItems && menuItems.length > 0) {
                const firstMenuItem = menuItems[0];
                this.pagecontent = firstMenuItem;
                this.imageUrl = firstMenuItem.extra1;
                console.log(firstMenuItem.contentIcon, firstMenuItem.contentFileName);
                this.dataAvailable = true;
            } else {
                this.dataAvailable = false;

                this.router.navigate(['/']);
            }
            this.service.App.ShowLoader = false;
        }).catch(error => {
            console.error('Error fetching submenus:', error);
            this.dataAvailable = false;
            this.service.App.ShowLoader = false;
        });
    }

    createRequestForm(): void {
        this.requestForm = this.fb.group({
            yourName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phoneNo: [''],
            dateTime: [''],
            yourMessage: ['', Validators.required],
        });
    }

    async onSubmit(): Promise<void> {
        if (this.requestForm.valid) {
            const obj = this.requestForm.getRawValue();
            console.log(JSON.stringify(obj));
            const res = this.requestCallBackService.addRequestCallBack(obj);
            if (res) {
                this.alertify.success("Requested Sent Successfully");
            }
        }
        this.requestForm.reset();
    }
}
