<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Video gallery</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Video</li>
            </ul>
        </div>
    </div>
</div>

<section class="category-area pt-100">
    <!-- <div class="container">
        <div class="section-title ">
            <span>The Categories</span>
            <h2>Search <b>By Category</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-bake"></i></a>
                    <a routerLink="/category"><h3>Restaurant</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-hotel"></i></a>
                    <a routerLink="/category"><h3>Hotel</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-barbell"></i></a>
                    <a routerLink="/category"><h3>Fitness</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-store"></i></a>
                    <a routerLink="/category"><h3>Shops</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-event"></i></a>
                    <a routerLink="/category"><h3>Events</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6">
                <div class="category-box">
                    <a routerLink="/category"><i class="flaticon-flower"></i></a>
                    <a routerLink="/category"><h3>Beauty</h3></a>
                </div>
            </div>
        </div>
    </div> -->
</section>

<section class="city-area-two pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span>Videos</span>
                    <h2>Video Gallery</h2>
                    <p>List of videos</p>
                </div>
            </div>

            <!-- <div class="col-lg-4">
                <div class="city-btn">
                    <a routerLink="/" class="default-btn">View all places <i class='bx bx-plus'></i></a>
                </div>
            </div> -->
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let obj of galleryItems">
                <div class="city-card">
                    <ngx-youtube-player [youtubeId]="obj.VideoKey" [height]="300" [width]="400" [autoPlay]="false"
                        [hideControls]="true" [autoFullscreen]="true" (readyChange)="onReadyChange($event)">
                        <img class="cover" [ngClass]="{hide: isReady}" src="https://i.ytimg.com/vi/' + obj.videoId + '/' + obj.videoId + '.jpg" alt="">
                    </ngx-youtube-player>
                    <div class="content">
                        <a routerLink="/"><h3>{{obj.videoCategory}}</h3></a>
                            <span>{{obj.videoDescp}}</span>
                            <a [routerLink]="['/', obj.videoLink]"><h3>View</h3></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
