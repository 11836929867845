import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { Banner } from '../model/banner';



@Injectable({
    providedIn: 'root'
})
export class MenuDetailsService {

    private apiPath: string;
    constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


    async getAllMenuDetails(){
        //return this.http.get<any>(this.apiPath + '/api/Banner');
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("MenuMaster");
          } catch (error) {
            console.error('Error fetching MenuDetail:', error);
            return [];
          }
    }

}
