<style>
    .image-container {
    display: flex;
    justify-content: center;
}

</style>


<app-navbar-style-guest></app-navbar-style-guest>



<div class="inner-banner inner-bg4">
    <div class="container">

        <div class="inner-banner-title text-center">
            <h3>{{pagecontent?.contentFileName}}</h3>

        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-7">
                    <ul class="list">
                        <li><a href="/listing">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active"><a routerLink="/listing">Page Content</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{pagecontent.contentFileName}}</li>
                    </ul>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-article">
                    <div class="article-comment-area">
                        <div class="image-container" *ngIf="imageUrl">
                            <img [src]="apiPath + '/Documents/appImages/' + pagecontent.extra1" alt="Images" width="300" height="300">
                        </div>
                        
                    </div>
                    <div class="article-content">
                        <div *ngIf="!pagecontent && !dataAvailable">

                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title text-center">
                                        <span>Please wait, We're loading some exciting content for you</span>
                                    </div>

                                    <div *ngIf="!pagecontent && !dataAvailable" class="error-message">
                                        <p>No data available for the provided ID.</p>
                                    </div>

                                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                                </div>
                            </div>
                        </div>
                        <h2 class="postCardTitle text-capitalize">{{pagecontent?.contentFileName}}</h2>
                        <p><br></p>
                        <div class="card-text postCardText" [innerHTML]="pagecontent.contentType"></div>
                        <div class="blog-tag">
                            <ul>
                                <li class="active">Content Icon:</li>
                                <li><a routerLink="/blog"> {{pagecontent.contentIcon}}</a></li>
                            </ul>
                        </div>
                        <div *ngIf="isLoading" class="overlay-inner">
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side">
                        <h3 class="title">Contact Us</h3>

                        <div class="listing-Booking-form ">
                            <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" formControlName="yourName" class="form-control" required
                                                placeholder="Your Name*">
                                            <ng-container *ngIf="requestForm   && requestForm.get('yourName')">
                                                <div
                                                    *ngIf="requestForm.get('yourName').hasError('required') && requestForm.get('yourName').touched">
                                                    Your Name is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='flaticon-email-2'></i>
                                            <input type="email" formControlName="email" class="form-control" required
                                                placeholder="E-mail*">
                                            <ng-container *ngIf="requestForm  && requestForm.get('email')">
                                                <div
                                                    *ngIf="requestForm.get('email').hasError('required') && requestForm.get('email').touched">
                                                    Email is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-phone'></i>
                                            <input type="text" formControlName="phoneNo" required class="form-control"
                                                placeholder="Your Phone">
                                            <ng-container *ngIf="requestForm && requestForm.get('phoneNo')">
                                                <div
                                                    *ngIf="requestForm.get('phoneNo').hasError('required') && requestForm.get('phoneNo').touched">
                                                    PhoneNo is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class="flaticon-calendar"></i>
                                            <input type="text" formControlName="dateTime" required class="form-control"
                                                placeholder="Date & Time">
                                            <ng-container *ngIf="requestForm && requestForm.get('dateTime')">
                                                <div
                                                    *ngIf="requestForm.get('dateTime').hasError('required') && requestForm.get('dateTime').touched">
                                                    DateTime is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea formControlName="yourMessage" class="form-control" cols="30"
                                                rows="8" required placeholder="Your Message"></textarea>
                                            <ng-container *ngIf="requestForm && requestForm.get('yourMessage')">
                                                <div
                                                    *ngIf="requestForm.get('yourMessage').hasError('required') && requestForm.get('yourMessage').touched">
                                                    Message is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn border-radius">Send Message <i
                                            class='bx bx-plus'></i></button>
                                </div>
                            </form>



                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>