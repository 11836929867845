import { Component, OnInit } from '@angular/core';
import { PostsService } from '../../services/posts.service';
import { AddCommentService } from '../../services/comments.service';
import { Blog } from '../../model/blog';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { LogoService } from '../../services/logo.service';
@Component({
  selector: 'app-navbar-style-user',
  templateUrl: './navbar-style-user.component.html',
  styleUrls: ['./navbar-style-user.component.scss']
})
export class NavbarStyleUserComponent implements OnInit {

  commentCount: any;
  userName = localStorage.getItem('token');
  userEmail = localStorage.getItem('email');
  posts: Blog[];
  postCount: number;
  imageUrl: string;
  apiPath: any;
  logoData: any;

  constructor(private LogoService: LogoService, private commentService: AddCommentService,
    private postService: PostsService, private router: Router, private services: SystemService) {
    this.apiPath = this.services.Settings.Base_API_URL;
    this.loadlogo();
  }

  ngOnInit() {
    this.commentService.getUserCommentCount(this.userName).subscribe(
      comment => {
        this.commentCount = comment;
      }
    );

    this.postService.getUserPosts(this.userName).subscribe(
      posts => {
        this.posts = posts;
        this.postCount = posts.length;
      }
    );
  }
  async loadlogo() {
    await this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
      this.logoData = logoData;
      console.log(this.logoData, "logoDataGetLatestCompanyDetailslatest");
      this.imageUrl = this.apiPath + "/Documents/appImages/" + this.logoData.logoPath;
      console.log(this.imageUrl, "this.imageUrlheader")
    });

  }

  logout() {
    console.log("logOut");
    this.services.logOut();
    localStorage.setItem('token', "");
    localStorage.setItem('email', "");
    localStorage.setItem('admin', "");
    localStorage.setItem('id', "");
    localStorage.clear();
    this.router.navigate(['/']);
    //   window.location.reload();
  }
}
