import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageGallery } from '../../model/imagegallery';
import { ImageGalleryService } from '../../services/imagegallery.service';
import { SystemService } from '../../services/system.service';

@Component({
    selector: 'app-imagegallerypicture',
    templateUrl: './imagegallerypicture.component.html',
    styleUrls: ['./imagegallerypicture.component.scss']
})
export class ImagegalleryPictureComponent implements OnInit {
    apiPath: string;
    galleryBuget: string;
    imagelist: ImageGallery[];
    obj: any = {}; // Define and initialize obj here

    constructor(
        private route: ActivatedRoute,
        private imagegalleryservice: ImageGalleryService,
        public service: SystemService
    ) { }

    ngOnInit() {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.route.paramMap.subscribe(params => {
            this.galleryBuget = params.get('galleryBuget');
            this.obj.galleryBuget = this.galleryBuget; // Assign value to obj.galleryBuget
            this.loadImageGallery(this.galleryBuget);
        });
    }

    loadImageGallery(galleryBuget: string) {
        this.service.App.ShowLoader = true;
        this.imagegalleryservice.getAllImages().then(images => {
            this.imagelist = images.filter(image => image.galleryBuget === galleryBuget);
        }).finally(() => {
            this.service.App.ShowLoader = false;
        });
    }
}
