<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <!-- <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li> -->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>Get know</span>
                        <h2>HRM VISAS</h2>
                        <p>We have been in this specific field of Visa Services since 2008. We have gained experience by processing Thousands of Visa applications.</p>
                        <p>We have been in this specific field of Visa Services since 2008. We have gained experience by processing Thousands of Visa applications. We do Visas, Travel, Flight, Hotel, Insurance, Documentation make easy!</p>

                    </div>

                    <!-- <div class="about-list">
                        <ul>
                            <li><i class="flaticon-curve-arrow"></i> Mea omnium explicari sit vidit harum</li>
                            <li><i class="flaticon-curve-arrow"></i> His no legere feugaitoer in laudem</li>
                            <li><i class="flaticon-curve-arrow"></i> Temporeum dicant partem scripserit, doctus</li>
                        </ul>
                    </div>
                    <a routerLink="/team" class="default-btn border-radius">Our Team <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/Aboutus.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="choose-area">
    <div class="container">
        <div class="section-title text-center">
            <span>About</span>
            <h2>OUR MISSION AND PURPOSE</h2>


            <p>Our company has been established with the mission to provide the best possible and continuous support in the our customer and services to make a positive impact on their lives.

              </p>
        </div>

        <div class="choose-width pt-100 pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>Call us</h3>
                       <p>+91 97430-04400</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-support"></i>
                        <h3>Skype</h3>
                       <p>&#64;HRMVISAS</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-card">
                        <i class="flaticon-web-page"></i>
                        <h3>Email us</h3>
                        <p>admin&#64;hrmvisas.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>We hope that you find it helpful.</h2>
            <!-- <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="play-btn"><i class='bx bx-play' ></i></a> -->
        </div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3></h3>
                        <span>Students</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3></h3>
                        <span>Families</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3></h3>
                        <span>Business People</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3></h3>
                        <span>Individuals</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--
<section class="process-area">
    <div class="process-into process-into-2  pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Process</span>
                <h2>See <b>How It Works</b></h2>
                <p>Porem ipsum dolor sit ame consectetur adipisicing incididunt </p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Find Interesting Place</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Choose What To Do</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Find What You Want</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section>

<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team</span>
            <h2>Our Skilled Team</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/team1.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Austin Evon</a></h3>
                        <span>Co-manager associated</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/team2.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Taylor Anderson</a></h3>
                        <span>Business consultant</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/team3.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Jorgia Klev</a></h3>
                        <span>Business Manger</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="client-area pt-100">
    <div class="container">
        <div class="client-bg">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Oli Rubion</h3>
                                <span>Rubion Inc</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Sanaik Tubi</h3>
                                <span>Arbon Restaurant</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Mashrof Ruin</h3>
                                <span>Pice Cafe</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Download app</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/mobile2.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
