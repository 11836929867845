<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Frequently Asked Questions</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Frequently Asked Questions</li>
            </ul>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>FAQ</span>
            <h2>Listing <b>FAQ</b></h2>
        </div>

        <div class="row pt-45" *ngFor="let item of rowData">
            <div class="col-lg-6">
                <div class="faq-item">

                    <h3>Q: {{ item.faqQuestions }}</h3>
                    <p><strong>A:</strong>{{ item.faqAnswer }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
